import React from "react";
import ReactDOM from "react-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/index.scss";
import "./assets/css/demo.css";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
