import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { UploadQueueManager } from "../upload-queue-manager";
// import { onFilePercentageChange } from "../helper-methods/index";

class MediaQueueViewer extends Component {
  state = { queueCount: 0, percentage: 0 };

  componentDidMount() {
    UploadQueueManager.onQueueCountChange((queueCount) => {
      this.setState({ queueCount });
    });

    // onFilePercentageChange((percentage) => {
    //   this.setState({ percentage: percentage !== "100%" ? percentage : 0 });
    // });
  }

  render() {
    const { queueCount, percentage } = this.state;

    return (
      <>
        {queueCount > 0 ? (
          <div id="mediaQueueViewerWrapper">
            <div id="uploadeProgressHeader">
              <Spinner
                style={{
                  width: "1.3rem",
                  height: "1.3rem",
                  marginRight: 12,
                }}
              />
              {queueCount > 1
                ? `${queueCount} uploads in Progress`
                : `${queueCount} upload in Progress`}{" "}
              {percentage ? `${percentage}` : null}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default MediaQueueViewer;
