import platfrom from "platform";
import cuid from "cuid";

export default class DeviceMetaData {
  static getBrowser = () => {
    return platfrom.name;
  };

  static getOs = () => {
    return platfrom.os;
  };

  static getDevice = () => {
    return platfrom.product;
  };

  static getDeviceId = () => {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = cuid();
      localStorage.setItem("deviceId", deviceId);
    }
    return deviceId;
  };
}
