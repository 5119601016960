export const StatesConfig = {
  IN: [
    { code: "AN", name: "Andaman and Nicobar Islands" },
    { code: "AP", name: "Andhra Pradesh" },
    { code: "AR", name: "Arunachal Pradesh" },
    { code: "AS", name: "Assam" },
    { code: "BR", name: "Bihar" },
    { code: "CG", name: "Chandigarh" },
    { code: "CH", name: "Chhattisgarh" },
    { code: "DN", name: "Dadra and Nagar Haveli" },
    { code: "DD", name: "Daman and Diu" },
    { code: "DL", name: "Delhi" },
    { code: "GA", name: "Goa" },
    { code: "GJ", name: "Gujarat" },
    { code: "HR", name: "Haryana" },
    { code: "HP", name: "Himachal Pradesh" },
    { code: "JK", name: "Jammu and Kashmir" },
    { code: "JH", name: "Jharkhand" },
    { code: "KA", name: "Karnataka" },
    { code: "KL", name: "Kerala" },
    { code: "LA", name: "Ladakh" },
    { code: "LD", name: "Lakshadweep" },
    { code: "MP", name: "Madhya Pradesh" },
    { code: "MH", name: "Maharashtra" },
    { code: "MN", name: "Manipur" },
    { code: "ML", name: "Meghalaya" },
    { code: "MZ", name: "Mizoram" },
    { code: "NL", name: "Nagaland" },
    { code: "OR", name: "Odisha" },
    { code: "PY", name: "Puducherry" },
    { code: "PB", name: "Punjab" },
    { code: "RJ", name: "Rajasthan" },
    { code: "SK", name: "Sikkim" },
    { code: "TN", name: "Tamil Nadu" },
    { code: "TS", name: "Telangana" },
    { code: "TR", name: "Tripura" },
    { code: "UP", name: "Uttar Pradesh" },
    { code: "UK", name: "Uttarakhand" },
    { code: "WB", name: "West Bengal" },
  ],
  US: [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "American Samoa", code: "AS" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "District Of Columbia", code: "DC" },
    { name: "Federated States Of Micronesia", code: "FM" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Guam", code: "GU" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Palau", code: "PW" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virgin Islands", code: "VI" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
  ],
  MX: [
    {
      name: "Distrito Federal",
      code: "MX-DIF",
      subdivision: "federal district",
    },
    {
      name: "Aguascalientes",
      code: "MX-AGU",
      subdivision: "state",
    },
    {
      name: "Baja California",
      code: "MX-BCN",
      subdivision: "state",
    },
    {
      name: "Baja California Sur",
      code: "MX-BCS",
      subdivision: "state",
    },
    {
      name: "Campeche",
      code: "MX-CAM",
      subdivision: "state",
    },
    {
      name: "Chiapas",
      code: "MX-CHP",
      subdivision: "state",
    },
    {
      name: "Chihuahua",
      code: "MX-CHH",
      subdivision: "state",
    },
    {
      name: "Coahuila",
      code: "MX-COA",
      subdivision: "state",
    },
    {
      name: "Colima",
      code: "MX-COL",
      subdivision: "state",
    },
    {
      name: "Durango",
      code: "MX-DUR",
      subdivision: "state",
    },
    {
      name: "Guanajuato",
      code: "MX-GUA",
      subdivision: "state",
    },
    {
      name: "Guerrero",
      code: "MX-GRO",
      subdivision: "state",
    },
    {
      name: "Hidalgo",
      code: "MX-HID",
      subdivision: "state",
    },
    {
      name: "Jalisco",
      code: "MX-JAL",
      subdivision: "state",
    },
    {
      name: "Michoacán",
      code: "MX-MIC",
      subdivision: "state",
    },
    {
      name: "Morelos",
      code: "MX-MOR",
      subdivision: "state",
    },
    {
      name: "México",
      code: "MX-MEX",
      subdivision: "state",
    },
    {
      name: "Nayarit",
      code: "MX-NAY",
      subdivision: "state",
    },
    {
      name: "Nuevo León",
      code: "MX-NLE",
      subdivision: "state",
    },
    {
      name: "Oaxaca",
      code: "MX-OAX",
      subdivision: "state",
    },
    {
      name: "Puebla",
      code: "MX-PUE",
      subdivision: "state",
    },
    {
      name: "Querétaro",
      code: "MX-QUE",
      subdivision: "state",
    },
    {
      name: "Quintana Roo",
      code: "MX-ROO",
      subdivision: "state",
    },
    {
      name: "San Luis Potosí",
      code: "MX-SLP",
      subdivision: "state",
    },
    {
      name: "Sinaloa",
      code: "MX-SIN",
      subdivision: "state",
    },
    {
      name: "Sonora",
      code: "MX-SON",
      subdivision: "state",
    },
    {
      name: "Tabasco",
      code: "MX-TAB",
      subdivision: "state",
    },
    {
      name: "Tamaulipas",
      code: "MX-TAM",
      subdivision: "state",
    },
    {
      name: "Tlaxcala",
      code: "MX-TLA",
      subdivision: "state",
    },
    {
      name: "Veracruz",
      code: "MX-VER",
      subdivision: "state",
    },
    {
      name: "Yucatán",
      code: "MX-YUC",
      subdivision: "state",
    },
    {
      name: "Zacatecas",
      code: "MX-ZAC",
      subdivision: "state",
    },
  ],
};
