import React from "react";

const ErrorNotFound = () => {
  return (
    <div className="container text-center">
      <h2 className="mt-5">Page not found!</h2>
      <h1 className="mt-1">404</h1>
    </div>
  );
};

export default ErrorNotFound;
