import { UPDATE_SETTINGS, CLEAR_SETTINGS } from "../actions/";

// complimentaryServices: {},
// amenityCategories: [],
// categories: [] // not using in future
// serviceOptions: [] // not using in future
const initialState = {};

export const settingsReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case UPDATE_SETTINGS: {
      newState["complimentaryServices"] =
        action.payload.setting?.complimentaryServices || {};
      newState["amenityCategories"] = action.payload.setting?.amenityCategories
        ?.length
        ? action.payload.setting.amenityCategories
        : [];

      newState["categories"] = action.payload.setting?.categories?.length
        ? action.payload.setting.categories
        : [];
      newState["serviceOptions"] = action.payload.setting?.serviceOptions
        ?.length
        ? action.payload.setting.serviceOptions
        : [];
      break;
    }
    case CLEAR_SETTINGS: {
      newState = initialState;
      break;
    }
    default:
  }
  return newState;
};
