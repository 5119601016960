import { CLEAR_SETTINGS, UPDATE_SETTINGS } from ".";
import { getSettings } from "../../http/http-calls";

export const updateSettings = (setting) => {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      setting,
    },
  };
};

export const clearSettings = () => {
  return {
    type: CLEAR_SETTINGS,
  };
};

export const getAndUpdateSettings = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      getSettings()
        .then((res) => {
          dispatch(updateSettings(res.setting));
          resolve(res);
        })
        .catch((error) => {
          console.log("error>>", error);
          reject(error);
        });
    });
  };
};
